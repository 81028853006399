import React from 'react';
import "./style.css";
import whatsapp from "../../assets/images/whatsapp.png";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import email from "../../assets/images/email.png";
import ContactForm from '../../Components/ContactForm/ContactForm';
import ContactData from '../../Components/ContactData/ContactData';

function Contact() {
    return (
        <div className="container mx-auto">
            <h1 className="py-8 text-center font-bold">CONTACTANOS</h1>
            <p>Escribínos tu consulta y te responderemos a la brevedad</p>
            <div className="px-8 max-w-xl justify-around mx-auto bg-white rounded-xl shadow-lg space-y-2 flex sm:items-center sm:space-y-0 sm:space-x-6">
                <a className="py-5 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110" href="https://wa.me/5491151494430?text=Hola%2C+queria+consultar+el+precio+de+" target="_blank" rel="noreferrer" aria-label="Whatsapp Carlos">
                    <img className="my-5 block mx-auto h-16 sm:mx-0 sm:flex-shrink-0" src={whatsapp} alt="Whatsapp" />
                </a>

                <a className="py-5 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110" href="https://www.facebook.com/sanitarioslaquila" target="_blank" rel="noreferrer" aria-label="Facebook">
                    <img className="my-5 block mx-auto h-16 sm:mx-0 sm:flex-shrink-0" src={facebook} alt="Facebook" />
                </a>

                <a className="py-5 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110" href="https://www.instagram.com/sanitarios_laquila" target="_blank" rel="noreferrer" aria-label="Instagram">
                    <img className="my-5 block mx-auto h-16 sm:mx-0 sm:flex-shrink-0" src={instagram} alt="Instagram" />
                </a>

                <a className="py-5 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110" href="mailto:marmolesgranitos@gmail.com" target="_self" aria-label="Enviar email">
                    <img className="my-5 block mx-auto h-16 sm:mx-0 sm:flex-shrink-0" src={email} alt="Email" />
                </a>
            </div>

            <div className="px-8 pt-8 grid grid-cols-1 sm:grid-cols-2">
                <div className="flex pt-8 grid grid-cols-1">
                    <ContactData />
                </div>
                <div className="flex pt-8 grid grid-cols-1">
                    <ContactForm />
                </div>
            </div>

            <div className="pt-8 text-center">
                <p>Atendemos al público de lúnes a viernes de 09:00 a 12:00 y 14:00 a 17:00</p>
            </div>

            <div className="pt-8 mx-auto max-w-lg">
                <iframe width="100%" height="400px" loading="lazy" src="https://maps.google.com/?ie=UTF8&amp;t=m&output=embed&amp;q=balbastro%202588%2C%20ciudad%20autonoma%20de%20buenos%20aires%2C%20buenos%20aires%2C%20argentina" title="Mapa ubicacion marmoleria"></iframe>
            </div>
        </div>
    );
}

export default Contact;


