import React from 'react';
import "./style.css";

export default function LoadingSpinner(props) {
    if (props.show) {
        return (
            <>
                <h3 className="text-center mb-8">Cargando, por favor espere ...</h3>
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </>
        );
    }
}
