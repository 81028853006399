import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from 'react-scroll'
import down_arrow from "../../assets/images/down_arrow.png";

const ConditionalLink = ({ children, to, link }) => {
    return (
    (!!to)
      ? <Link className="text-base font-medium text-gray-500 hover:text-gray-900 link cursor-pointer">{children}</Link>
      : <Link activeClass="active" to={link} spy={true} smooth={true} offset={-80} duration={500} className="text-base font-medium text-gray-500 hover:text-gray-900 link cursor-pointer">{children}</Link >
)};

const SingleItem = (description, link) => {
    return (
        <h2 key={description}>
            <ConditionalLink link={link}>
                {description}
            </ConditionalLink>
        </h2>
    );
}

const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
}

export default function MenuItem(props) {
    return (
        <>
            { props.items ?
                <Popover className="relative">
                    {({ open }) => (
                        <>
                            <Popover.Button
                                className={classNames(
                                    open ? "text-gray-900" : "text-gray-500",
                                    "group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none "
                                )}
                            >
                                <span className="link">{props.description}</span>
                                <img className={classNames(
                                    open ? "text-gray-600" : "text-gray-400",
                                    "hidden sm:block ml-2 h-5 w-5 group-hover:text-gray-500")} src={down_arrow} alt="Expandir" />

                            </Popover.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel
                                    static
                                    className="absolute z-10 -ml-4 mt-3 transform px-2 max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                            {props.items.map((item) => (
                                                SingleItem(item.description, item.link)
                                            ))}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
                :
                SingleItem(props.description, props.link)
            }
        </>
    );
}
