import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { Constants } from "../../Utils/Constants";
import "./style.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const imagesList = require.context('../../../public/nuestros-trabajos', false, /\.(png|jpe?g|svg|webp)$/);

function OurWork() {
  const [images, setImages] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slidePage, setSlidePage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getImagesFromPublicFolder();
    return () => {
      setImages([]);
      setSlideIndex(0);
      setSlidePage(1);
      setFirstLoad(true);
      setLoading(true);
    };
  }, []);

  const getImagesFromPublicFolder = () => {
    const imageUrls = imagesList.keys().map(element => element.replace("./", ""));
    setImages((oldImages) => [...oldImages, ...imageUrls]);
    setLoading(false);
    setFirstLoad(false);
  };

  const slideBack = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    } else if (!loading || (slidePage > 1 && loading)) {
      const pageToChange = slidePage > 1 ? slidePage - 1 : maxPage();
      setSlidePage(pageToChange);
      setSlideIndex(getItemsInPage(pageToChange) - 1);
    }
  };

  const slideForward = () => {
    if (slideIndex < 4 && slideIndex !== getItemsInPage(slidePage) - 1) {
      setSlideIndex(slideIndex + 1);
    } else if (!loading || (slidePage < maxPage() && loading)) {
      const pageToChange = slidePage < maxPage() ? slidePage + 1 : 1;
      setSlidePage(pageToChange);
      setSlideIndex(0);
    }
  };

  const maxPage = () => {
    return Math.ceil(images.length / Constants.IMAGES_PER_SLIDE_PAGE);
  };

  const arrayMinIndex = () => {
    return (slidePage - 1) * Constants.IMAGES_PER_SLIDE_PAGE;
  };

  const arrayMaxIndex = () => {
    return (slidePage - 1) * Constants.IMAGES_PER_SLIDE_PAGE + Constants.IMAGES_PER_SLIDE_PAGE;
  };

  const currentImageIndex = () => {
    return slideIndex + (Constants.IMAGES_PER_SLIDE_PAGE * slidePage - Constants.IMAGES_PER_SLIDE_PAGE);
  };

  const getItemsInPage = (page) => {
    const maxItems = page * Constants.IMAGES_PER_SLIDE_PAGE;
    const itemsDiff = images.length - maxItems;

    return itemsDiff > 0 ? Constants.IMAGES_PER_SLIDE_PAGE : Constants.IMAGES_PER_SLIDE_PAGE - Math.abs(itemsDiff);
  };

  const getImagePath = (image) => {
    return process.env.PUBLIC_URL  + '/' + Constants.NUESTROS_TRABAJOS_PATH + '/' + image;
  }

  return (
    <>
      <div className="container mx-auto px-4">
        <h1 className="py-8 text-center font-bold">NUESTROS TRABAJOS</h1>
        <section className="mx-auto max-w-2xl mb-8">
          {firstLoad && images.length == 0 ? (
            <LoadingSpinner show={firstLoad} />
          ) : (
            <div className="shadow-sm relative">
              <div className="current-image-container flex items-center justify-center overflow-hidden">
                <TransitionGroup className="contents">
                  <CSSTransition appear={true} timeout={{enter: 300, exit: 0}} classNames="img-animation" key={slideIndex}>
                    <img className=" current-image object-fill" src={images.length > 0 ? getImagePath(images[currentImageIndex()]) : ""} alt="Trabajo terminado" />
                  </CSSTransition>
                </TransitionGroup>
                <a className="h-inherit absolute left-0 inset-y-0 flex items-center px-4 text-white hover:text-gray-800 cursor-pointer text-3xl font-extrabold" onClick={slideBack} aria-label="Anterior">
                  ❮
                </a>
                <a className="h-inherit absolute right-0 inset-y-0 flex items-center px-4 text-white hover:text-gray-800 cursor-pointer text-3xl font-extrabold" onClick={slideForward} aria-label="Siguiente">
                  ❯
                </a>
              </div>
              <div className="flex">
                {images.slice(arrayMinIndex(), arrayMaxIndex()).map((image, index) => {
                  return (
                    <div key={index} className="w-1/5 cursor-pointer img-hover-zoom">
                      <img onClick={() => setSlideIndex(index)} src={getImagePath(image)} className={"image-thumbnail object-cover h-24 hover:opacity-100 cursor-pointer " + (slideIndex === index ? "opacity-100" : "opacity-50")} alt="Trabajo terminado" />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default OurWork;
