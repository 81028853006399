import React from "react";
import "./style.css";
import categorias from '../../assets/productos.json';

function Product() {
  return (
    <div className="container mx-auto px-4">
      <h1 className="py-8 text-center font-bold">PRODUCTOS</h1>
      {categorias.sortBy("order").map((categoria) => {
        return (
          <>
            {categoria.subcategories.sortBy("order").map((subcategoria) => {
              return (
                <section id={categoria.name} className="mx-auto mb-8">
                  <h2 className="uppercase mb-5">{categoria.name} {subcategoria.name}</h2>
                  <div className="inline-flex flex-wrap justify-center gap">
                    {subcategoria.products.sortBy("order").map((product, index) => {
                      return (
                        <div key={index} className="w-40 producto-container">
                          <div className="cursor-pointer img-hover-zoom overflow-hidden rounded-md producto-shadow">
                            <img src={product.image} className={"image-thumbnail object-cover hover-product cursor-pointer h-40 w-40"} alt={product.name}/>
                          </div>
                          <div className="h-5 text-black truncate producto-texto">{product.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              )})}
          </>
        )
      })}
    </div>
  );
}

export default Product;
