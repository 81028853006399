import React from 'react';
import "./style.css";

function Estimate() {
    return (
        <>
            <div className="py-8 text-center font-bold">
                <h1>PRESUPUESTO</h1>
            </div>
            <h2>Cree un presupuesto con nuestra herramienta de simulación pero tenga en cuenta que el precio es estimado.</h2>
        </>
    );
}

export default Estimate;


