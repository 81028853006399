import React, { useState } from "react";
import { Constants } from "../../Utils/Constants";
import ModalDialog from '../../Components/ModalDialog/ModalDialog';

export default function ContactForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalProperties, setModalProperties] = useState({
        handleAcceptButton: function () { setShowModal(false); },
        handleCancelButton: function () { setShowModal(false); },
        acceptButtonText: 'Aceptar',
        showAcceptButton: true,
        showCancelButton: false,
    });

    const showModalDialog = (title, message, type) => {
        setModalProperties((oldState) => ({
            ...oldState,
            title: title,
            message: message,
            type: type
        }));
        setShowModal(true);
    }

    const sendEmail = () => {
        const userId = process.env.REACT_APP_EMAILJS_USERID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID;

        setSending(true);

        const templateParams = {
            email,
            name,
            message
        };

        window.emailjs
            .send(
                "service_r42x80r",
                templateId,
                templateParams,
                userId
            )
            .then(res => {
                if (res.status === 200) {
                    showModalDialog("Enviado", "Gracias por escribirnos, en breves nuestro equipo se pondrá en contacto con usted.", Constants.DIALOG_TYPE.SUCCESS)
                }
            })
            // Handle errors here however you like
            .catch(err => {
                showModalDialog("Error", "Ocurrió un error. Por favor intente nuevamente más tarde.", Constants.DIALOG_TYPE.ERROR)
            }).finally(() => {
                setSending(false);
            });
    }

    return (
        <div>
            <p className="text-left">Escríbenos tu consulta ...</p>
            <div className="flex grid grid-cols-2 gap-4">
                <div className="flex grid grid-cols-1">
                    <p className="pt-3">
                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Tu nombre" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" maxLength="50" />
                    </p>
                    <p className="pt-3">
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Tu email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" maxLength="100" />
                    </p>
                </div>
                <div className="flex grid grid-cols-1">
                    <p className="pt-3">
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} rows="4" placeholder="Tu consulta ..." className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none" maxLength="500"></textarea>
                    </p>
                </div>
                <div className="flex grid grid-cols-1 justify-left">
                    <button onClick={sendEmail} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={sending} aria-label="enviar">
                        Enviar
                    </button>
                </div>
            </div>
            <ModalDialog {...modalProperties} show={showModal} />
        </div>
    );
}
