import React from 'react';
import whatsapp from "../../assets/images/whatsapp_mini.png";
import email from "../../assets/images/email_mini.png";
import location from "../../assets/images/pin_mini.png";
import "./style.css";

export default function ContactData() {
    return (
        <div className="flex grid text-left">
            <p>
                <img className="contact-logo" src={location} alt="Ubicación" />&nbsp;&nbsp;&nbsp;
                <a className="text-black" href="https://www.google.com/maps?q=balbastro+2588,+ciudad+autonoma+de+buenos+aires,+buenos+aires,+argentina" rel="noopener noreferrer" target="_blank" aria-label="Ubicación">Balbastro 2588, Ciudad Autónoma de Buenos Aires</a>
            </p>
            <p className="pt-3">
                <img className="contact-logo" src={whatsapp} alt="Whatsapp" />&nbsp;&nbsp;&nbsp;
                <a className="text-black" href="https://wa.me/5491151494430?text=Hola%2C+queria+consultar+el+precio+de+" target="_blank" rel="noopener noreferrer" aria-label="Whatsapp Carlos">+549 (011) 5149-4430 (Carlos)</a> <label className="mobile-hidden">/</label><br className='mobile-only' /><div className='mobile-only inline-block'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><a className="text-black" href="https://wa.me/5491138298971?text=Hola%2C+queria+consultar+el+precio+de+" target="_blank" rel="noopener noreferrer" aria-label="Whatsapp Sergio">+549 (011) 3829-8971 (Sergio)</a>
            </p>
            <p className="pt-3">
                <img className="contact-logo" src={email} alt="Email" />&nbsp;&nbsp;&nbsp;
                <a className="text-black" href="mailto:marmolesgranitos@gmail.com" target="_self" aria-label="Enviar email">marmolesgranitos@gmail.com</a>
            </p>
        </div>
    );
}
