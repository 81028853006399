import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import logo from "../../assets/images/logo_small.jfif";
import "./style.css";
import MenuItem from "../MenuItem/MenuItem";
import { Link } from 'react-scroll'
import menu from "../../assets/images/menu.png";
import close from "../../assets/images/close.png";

const menuItems = [
  {
    description: "INICIO",
    link: "home"
  },
  {
    description: "PRODUCTOS",
    mobile: false,
    items: [
      {
        description: "GRANITOS",
        link: "Granitos"
      },
      {
        description: "MÁRMOLES",
        link: "Mármoles"
      },
      {
        description: "CUARZOS",
        link: "Cuarzo"
      },
    ]
  },
  {
    description: "PRODUCTOS",
    link: "product",
    desktop: false,
  },
  {
    description: "NUESTROS TRABAJOS",
    link: "nuestrosTrabajos"
  },
  {
    description: "CONTACTO",
    link: "contact"
  },
  {
    description: "PRESUPUESTO",
    link: "/presupuesto"
  },
  {
    description: "NOSOTROS",
    link: "/nosotros"
  }
]

export default function Header() {
  return (
    <Popover id="header" className="relative z-10">
      {({ open }) => (
        <>
          <div className="mx-auto">
            <div className="flex md:justify-center items-center py-6 justify-end md:justify-end md:space-x-10 lg:justify-center">
              <div className="absolute left-4 block">
                <img id="logo-header" src={logo} alt="Marmolería Colaiacovo" />
              </div>

              <div className="-my-2 block md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <img className="h-6 w-6" src={menu} alt="Menu" aria-hidden="true" />
                </Popover.Button>
              </div>

              <Popover.Group as="nav" className="hidden md:flex space-x-10 items-baseline">
                {
                  menuItems.filter(mi => mi.desktop !== false).map((item) => {
                    return <MenuItem key={item.description + "-desktop"} {...item} />
                  })
                }
              </Popover.Group>
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-end float-right">
                    <div className="-mr-2">
                      <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <img className="h-6 w-6" src={close} alt="Menu" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <nav className="grid gap-y-8">
                    {
                      menuItems.filter(o => o.mobile !== false).map((item) => (
                        <Link
                          key={item.description + "-mobile"}
                          activeClass="active" to={item.link} spy={true} smooth={true} offset={-80} duration={500} className="text-base font-medium text-gray-500 hover:text-gray-900 link cursor-pointer"
                        >
                          <span className="ml-3 text-base font-medium text-gray-900">
                            {item.description}
                          </span>
                        </Link>
                      ))
                    }
                    </nav>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
