import React, { useRef } from 'react'
import envios from "../../assets/images/camion-de-reparto.png";
import atencion from "../../assets/images/headset.png";
import presupuesto from "../../assets/images/budget.png";
import medicion from "../../assets/images/measure.png";
import colocacion from "../../assets/images/tool-box.png";
import "./style.css";

const services = [
    {
        image: presupuesto,
        description: "Presupuesto en menos de 24hs",
        text: "Realizamos los mejores presupuestos en el rubro, ofreciendo precios inmejorables y un trabajo de excelente calidad."
    },
    {
        image: medicion,
        description: "Medición",
        text: "Nuestros especialistas se encargan de hacer la medición y asesorar en el proceso, "
    },
    {
        image: colocacion,
        description: "Colocación",
        text: ""
    },
    {
        image: envios,
        description: "Envíos a todo el país",
        text: "Enviamos nuestros productos a todo el país, no importa de dónde seas."
    },
    {
        image: atencion,
        description: "Atención post venta",
    }
]


function Services() {
    const itemsEls = useRef([])

    return (
            <div className="flex flex-wrap -m-4 text-center justify-center mt-32 px-8 pb-20 mobile:mt-12">
                {
                    services.map((service, index) => {
                        return (
                            <div key={service.description} className="animate__animated animate__fadeIn animate__delay-0-5s p-4 md:w-1/5 sm:w-1/3 w-1/2 h-full m-w-250" ref={(el) => (itemsEls.current[index] = el)} >
                                <div className="bg-white border-2 border-gray-600 px-4 py-6 rounded-lg min-h-223 flex flex-col justify-between">
                                    <img className="m-auto w-20 h-20 mb-6 mt-4" src={service.image} alt={service.description} />
                                    <p className="leading-relaxed">{service.description}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
    );
}

export default Services;


