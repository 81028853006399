import React from 'react';
import whatsapp from "../../assets/images/whatsapp.png";
import "./style.css";

export default function WhatsappIcon() {
    return (
        <a className="fixed right-3 bottom-3 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110" href="https://wa.me/5491151494430?text=Hola%2C+queria+consultar+el+precio+de+" target="_blank" rel="noreferrer">
            <img className="whatsapp-icon cursor-pointer" src={whatsapp} alt="Whatsapp" />
        </a>
    );
}
