import React from 'react';
import { BrowserRouter, } from "react-router-dom";
import { Route, Routes } from "react-router";
import Home from './pages/Home/Home';
import OurWork from './pages/OurWork/OurWork';
import Contact from './pages/Contact/Contact';
import Company from './pages/Company/Company';
import Estimate from "./pages/Estimate/Estimate";
import './App.css';
import 'animate.css'
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import WhatsappIcon from "./Components/WhatsappIcon/WhatsappIcon";
import Product from "./pages/Product/Product";
require("../src/Helpers/Helpers.js");

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div style={{minHeight: "calc(100vh - 80px)"}}>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/nuestros-trabajos" element={<OurWork />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/presupuesto" element={<Estimate />} />
          <Route path="/nosotros" element={<Company />} />
          <Route path="/productos/:type" element={<Product />} />
        </Routes>
      </div>
      <Footer />
      <WhatsappIcon />
    </BrowserRouter>
  );
}

export default App;
