export const Constants = {
    IMAGES_PER_SLIDE_PAGE: 5,
    DIALOG_TYPE: {
        SUCCESS: 0,
        ERROR: 1
    },
    PRODUCT_TYPE: {
        GRANITO: 1,
        MARMOL: 2
    },
    NUESTROS_TRABAJOS_PATH: 'nuestros-trabajos',
    PRODUCTOS_PATH: 'productos',
}
