import React from 'react'
import "./style.css";
import Services from '../../Components/Services/Services';
import HeaderText from '../../Components/HeaderText/HeaderText';
import Contact from '../Contact/Contact';
import OurWork from '../OurWork/OurWork';
import Product from '../Product/Product';

function Home() {
    return (
        <div id="homeContainer" className="container mx-auto max-w-100">
            <div className="block-main mx-auto max-w-6xl text-center">
                <article id="home" className="background-container pt-10">
                    <HeaderText />
                    <Services />
                </article>

                <article id="contact" className="contact-container" style={{backgroundColor: 'white', paddingBottom: '200px'}}>
                    <Contact />
                </article>

                <article id="nuestrosTrabajos" style={{backgroundColor: 'white', paddingBottom: '200px'}}>
                    <OurWork />
                </article>

                <article id="product" style={{backgroundColor: 'white', paddingBottom: '200px'}}>
                    <Product />
                </article>

                <article id="construccion">
                    <h2>El sitio se encuentra en construcción, pero nosotros seguimos trabajando para brindarte el mejor servicio! Contactanos hoy</h2>
                </article>
            </div>
        </div>
    );
}

export default Home;


