import React from 'react';
import "./style.css";

function Company() {
    return (
        <>
            <h1>Nosotros!</h1>
        </>
    );
}

export default Company;


