import React from "react";

export default function HeaderText() {
  return (
   <>
      <h1 className="animate__animated animate__fadeInUp">Marmolería Colaiacovo</h1>
      <h2 className="animate__animated animate__fadeInUp italic">El apellido del mármol</h2>
   </>
  );
}
