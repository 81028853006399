import React from 'react';
import logo from "../../assets/images/logo_small.jfif";
import "./style.css";
import ContactData from "../ContactData/ContactData";

export default function Footer() {
  return (
    <footer className="footer bg-white relative pt-1 border-t border-gray-300 text-center mt-4">
      <div className="container mx-auto">

        <div className="sm:flex sm:mt-8">
          <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex bg-white flex-col md:flex-row justify-between items-center md:items-start">
            <div className="flex flex-col flex-0 justify-center pt-5">
              <img src={logo} alt="Marmolería Colaiacovo" className="logo" />
            </div>
            <div className="flex flex-col pt-5 mobile:px-5">
              <span className="font-bold text-gray-700 uppercase mb-2">CONTACTO</span>
              <div className="sm:text-left text-center">
                <ContactData />
              </div>
            </div>
            <div className="flex flex-col pt-5">
              <span className="font-bold text-gray-700 uppercase mb-2">ATENCIÓN</span>
              <p>
                Lúnes a viernes de 09:00 a 12:00 y de 14:00 a 17:00
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-6 bg-white">
        <div className="mt-6 flex flex-col items-center">
          <div className="sm:w-2/3 text-center py-3">
            <p className="text-sm text-orange font-bold mb-2">
              © {(new Date().getFullYear())} - <a href="https://www.linkedin.com/in/gino-colaiacovo-947156bb/" rel="noopener noreferrer" target="_blank">Powered by Gino Colaiacovo</a>
                </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
